<template>
	<div>
		<img class="img-bg" v-if="bannerInfo" :src="bannerInfo[0]" alt="">
		<div class="advantage-box">
			<div class="main-title">
				<div class="big-title">我们的优势</div>
				<div class="small-title">— Our advantage —</div>
			</div>
			<div class="content-cls">
				<div class="detail-cls">飞飞鱼拥有全球性的S2B2C柔性供应链赋能平台，具有零库存、低成本、快反应、高定制的优势，<br>
					可以满足IP文创产业的多样化需求；智能化的管理平台可以保证全方位服务，集结原创设计、IP授权、衍生品开发等专业人才、企业及机构等，<br>
					尊重IP原创，聚焦IP的市场流量变现，促进IP文创产业发展壮大。</div>
				<div class="img-list">
					<img class="img-cls" src="../assets/contact/advantage_1.png" alt="">
					<img class="img-cls" src="../assets/contact/advantage_2.png" alt="">
					<img class="img-cls" src="../assets/contact/advantage_3.png" alt="">
					<img class="img-cls" src="../assets/contact/advantage_4.png" alt="">
				</div>
			</div>
		</div>

		<div class="contact-box">
			<div class="main-title">
				<div class="big-title">联系我们</div>
				<div class="small-title">— contact us —</div>
			</div>
			<div class="content-cls contact-list">
				<div class="item-cls" v-for="item in contactway" :key="item.id">
					<div class="img-cls">
						<img :src="item.img_id" alt="">
					</div>
					<div class="detail-box">
						<div class="title-cls">{{item.title}}</div>
						<div class="detail-item">
							<p class="border-btm">{{item.intro}}</p>
							<p>电话：{{item.phone}}</p>
							<p>邮箱：{{item.email}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="join-box">
			<div class="main-title">
				<div class="big-title">加入我们</div>
				<div class="small-title">— JOIN us —</div>
			</div>
			<div class="content-cls">
				<div class="form-box">
					<div class="form-item">
						<div class="label-name">姓名</div>
						<input type="text" class="input-cls" v-model="params.nickname" value="飞飞鱼" placeholder="请输入您的姓名">
					</div>
					<div class="form-item">
						<div class="label-name">电话</div>
						<input type="text" class="input-cls" v-model="params.phone" placeholder="请输入您的手机号码">
					</div>
					<div class="form-item">
						<div class="label-name">邮箱</div>
						<input type="text" class="input-cls" v-model="params.email" placeholder="请输入您的邮箱">
					</div>
					<div class="form-item">
						<div class="label-name">需求</div>
						<input type="text" class="input-cls" v-model="params.demand" placeholder="请填写您的大概需求">
					</div>
					<div class="submit-btn" @click="submitFn">提交</div>
				</div>
			</div>
		</div>
		
		<!-- 地址 -->
		<div class="address-box">
			<img src="../assets/address.jpg" alt="">
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	export default {
		data() {
			return {
				params: {
					nickname: '',
					phone: '',
					email: '',
					demand: ''
				},
				contactway:[]
			}
		},
		computed: {
			...mapState({
				bannerInfo: state => state.bannerInfo[5], // banner图
			})
		},
		created(){
			this.getContactwayList()
		},
		methods: {
			// 联系我们
			getContactwayList(){
				this.$api.getContactwayList({}).then(res => {
					if (res.code == '0000') {
						this.contactway = res.data;
					}
				})
			},
			// 加入我们
			submitFn() {
				if (!this.params.nickname) {
					this.$message(({message: '姓名不能为空！', type: 'error'}));
					return
				} else if (!this.params.phone) {
					this.$message(({message: '手机不能为空！', type: 'error'}));
					return
				} else if (!this.params.email) {
					this.$message(({message: '邮箱不能为空！', type: 'error'}));
					return
				} else if (!this.params.demand) {
					this.$message(({message: '需求不能为空！', type: 'error'}));
					return
				}
				this.params.from = sessionStorage.getItem('from');
				// 提交数据
				this.$api.submitContact(this.params).then(res => {
					if (res.code == '0000') {
						this.$message({message: '提交成功，后续会有相关人员与您进行联系！',duration:6000});
						this.params ={
							nickname: '',
							phone: '',
							email: '',
							demand: ''
						};
					} else {
						this.$message(({message: res.msg, type: 'error'}));
					}
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.detail-cls {
		font-size: 18px;
		line-height: 30px;
		font-weight: 400;
		color: #7E7E7E;
		opacity: 0.8;
		line-height: 36px;
	}

	.advantage-box {
		padding: 90px 0;

		.content-cls {
			margin-top: 79px;
		}

		.img-list {
			margin-top: 80px;

			.img-cls {
				margin-left: 100px;
				height: 240px;
				width: 240px;
			}

			.img-cls:first-child {
				margin-left: 0;
			}
		}
	}

	.contact-box {
		padding: 90px 0;
		background: #fafafa;

		.contact-list {
			margin-top: 58px;

			.detail-box {
				text-align: center;
				color: #666;
				font-weight: 400;
				padding: 0 10px;
				white-space: nowrap;

				p {
					line-height: 20px;
				}
			}

			.item-cls {
				display: inline-block;
				width: 309px;
				text-align: center;
				vertical-align: top;
				margin-left: 160px;
			}

			.item-cls:first-child {
				margin-left: 0;
			}

			.img-cls {
				margin: 0 auto;
				width: 202px;
				>img{
					display:block;
				}
			}

			.title-cls {
				font-size: 24px;
				color: #333;
				margin-top: 28px;
			}

			.border-btm {
				border-bottom: 1px solid #e6e6e6;
				color: #333;
				padding: 8px 0;
				margin-bottom: 8px;
			}

		}
	}

	.join-box {
		padding: 90px 0;

		.content-cls {
			margin-top: 79px;
			position: relative;
		}

		.form-box {
			box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
			padding: 105px 300px 85px;
			position: relative;

			.form-item {
				border-bottom: 1px solid #e6e6e6;
				width: 240px;
				display: inline-block;
				text-align: left;
				margin-right: 60px;
				margin-bottom: 60px;
			}

			.form-item:nth-child(2n) {
				margin-right: 0;
			}

			.label-name {
				font-size: 14px;
				color: #666;
				margin-bottom: 20px;
			}

			.input-cls {
				border: 0;
				font-size: 18px;
				width: 240px;
				outline: none;
				margin-bottom: 10px;
				height: 24px;
				line-height: 24px;
			}
		}

		.submit-btn {
			width: 350px;
			height: 60px;
			line-height: 60px;
			background: #EBB149;
			border-radius: 30px;
			text-align: center;
			font-size: 18px;
			position: absolute;
			bottom: -60px;
			left: 50%;
			transform: translate(-50%, -50%);
			cursor: pointer;
		}

		.content-cls:before {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-top: 100px solid transparent;
			border-left: 170px solid #f9f8f8;
			border-bottom: 100px solid transparent;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
		
		.content-cls:after {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-top: 100px solid transparent;
			border-right: 170px solid #f9f8f8;
			border-bottom: 100px solid transparent;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}

	}
</style>
