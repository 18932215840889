<template>
	<div>
		<img class="img-bg" src="../assets/contact/top_bg.jpg" alt="">
		<div class="advantage-box">
			<div class="main-title">
				<div class="big-title">我们的优势</div>
				<div class="small-title">— Our advantage —</div>
			</div>
			<div class="content-cls">
				<div class="detail-cls">飞飞鱼拥有全球性的S2B2C柔性供应链赋能平台，具有零库存、低成本、快反应、高定制的优势，<br>
					可以满足IP文创产业的多样化需求；智能化的管理平台可以保证全方位服务，集结原创设计、IP授权、衍生品开发等专业人才、企业及机构等，<br>
					尊重IP原创，聚焦IP的市场流量变现，促进IP文创产业发展壮大。</div>
				<div class="img-list">
					<img class="img-cls" src="../assets/contact/advantage_1.png" alt="">
					<img class="img-cls" src="../assets/contact/advantage_2.png" alt="">
					<img class="img-cls" src="../assets/contact/advantage_3.png" alt="">
					<img class="img-cls" src="../assets/contact/advantage_4.png" alt="">
				</div>
			</div>
		</div>

		<div class="contact-box">
			<div class="main-title">
				<div class="big-title">联系我们</div>
				<div class="small-title">— contact us —</div>
			</div>
			<div class="content-cls contact-list">
				<div class="item-cls">
					<img class="img-cls" src="../assets/contact/contact_1.png" alt="">
					<div class="detail-box">
						<div class="title-cls">版权所有者</div>
						<div class="detail-item">
							<div class="border-btm">可以将IP授权给我们，共同开发衍生品</div>
							<p>姓名：王平</p>
							<p>电话：15392428710</p>
							<p>邮箱：3063935583@qq.com</p>
						</div>
					</div>
				</div>
				<div class="item-cls">
					<img class="img-cls" src="../assets/contact/contact_2.png" alt="">
					<div class="detail-box">
						<div class="title-cls">商家</div>
						<div class="detail-item">
							<div class="border-btm">可以采购我们的产品，在各大正规平台销售</div>
							<p>姓名：吴恒</p>
							<p>电话：17350037896</p>
							<p>邮箱：fiifishbusiness@inkdiy.com</p>
						</div>
					</div>
				</div>
				<div class="item-cls">
					<img class="img-cls" src="../assets/contact/contact_3.png" alt="">
					<div class="detail-box">
						<div class="title-cls">供应链</div>
						<div class="detail-item">
							<div class="border-btm">可以生产各类IP衍生品</div>
							<p>姓名：王平</p>
							<p>电话：15392428710</p>
							<p>邮箱：3063935583@qq.com</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="join-box">
			<div class="main-title">
				<div class="big-title">加入我们</div>
				<div class="small-title">— JOIN us —</div>
			</div>
			<div class="content-cls">
				<div class="form-box">
					<div class="form-item">
						<div class="label-name">姓名</div>
						<input type="text" class="input-cls" v-model="params.nickname" value="飞飞鱼" placeholder="请输入您的姓名">
					</div>
					<div class="form-item">
						<div class="label-name">电话</div>
						<input type="text" class="input-cls" v-model="params.phone" placeholder="请输入您的手机号码">
					</div>
					<div class="form-item">
						<div class="label-name">邮箱</div>
						<input type="text" class="input-cls" v-model="params.email" placeholder="请输入您的邮箱">
					</div>
					<div class="form-item">
						<div class="label-name">需求</div>
						<input type="text" class="input-cls" v-model="params.demand" placeholder="请填写您的大概需求">
					</div>
					<div class="submit-btn" @click="submitFn">提交</div>
				</div>
			</div>
		</div>
		
		<!-- 地址 -->
		<div class="address-box ">
			<van-image class="img-cls" :src="require('../assets/address.jpg')" fit="cover" lazy-load />
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				params: {
					nickname: '',
					phone: '',
					email: '',
					demand: ''
				}
			}
		},
		methods: {
			// 联系我们
			submitFn() {
				if (!this.params.nickname) {
					this.$message(({message: '姓名不能为空！', type: 'error'}));
					return
				} else if (!this.params.phone) {
					this.$message(({message: '手机不能为空！', type: 'error'}));
					return
				} else if (!this.params.email) {
					this.$message(({message: '邮箱不能为空！', type: 'error'}));
					return
				} else if (!this.params.demand) {
					this.$message(({message: '需求不能为空！', type: 'error'}));
					return
				}
				this.params.from = sessionStorage.getItem('from');
				// 提交数据
				this.$api.submitContact(this.params).then(res => {
					if (res.code == '0000') {
						this.$message({message: '提交成功，后续会有相关人员与您进行联系！'});
					} else {
						this.$message(({message: res.msg, type: 'error'}));
					}
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.detail-cls {
		font-size: 0.9rem;
		line-height: 1.5rem;
		font-weight: 400;
		color: #7E7E7E;
		opacity: 0.8;
	}

	.advantage-box {
		padding: 3rem 0 0;

		.content-cls {
			margin-top: 2rem;
			padding: 0 1rem;
		}

		.img-list {
			margin-top: 2rem;

			.img-cls {
				margin-right: 1rem;
				margin-bottom: 1rem;
				height: 10rem;
				width: 10rem;
			}

			.img-cls:nth-child(2n) {
				margin-right: 0;
			}
		}
	}

	.contact-box {
		padding: 3rem 0;
		background: #fafafa;

		.contact-list {
			margin-top: 2.5rem;

			.detail-box {
				text-align: left;
				color: #666;
				font-weight: 400;
				padding: 0 0.6rem;

				p {
					line-height: 1.3rem;
				}
				
				.detail-item>p{
					white-space: nowrap;
				}
			}

			.item-cls {
				display: inline-block;
				width: 10rem;
				text-align: center;
				vertical-align: top;
				margin-bottom: 2rem;
			}

			.item-cls:nth-child(2) {
				margin-left: 2rem;
			}

			.img-cls {
				width: 10rem;
			}

			.title-cls {
				font-size: 1.4rem;
				color: #333;
				margin-top: 1.4rem;
			}

			.border-btm {
				border-bottom: 1px solid #e6e6e6;
				padding: 0.4rem 0;
				margin-bottom: 0.5rem;
			}

		}
	}

	.join-box {
		padding: 3rem 0;
	
		.content-cls {
			margin-top: 2rem;
			position: relative;
		}
	
		.form-box {
			box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
			padding: 3rem 1rem;
			position: relative;
	
			.form-item {
				border-bottom: 1px solid #e6e6e6;
				width: 16rem;
				display: inline-block;
				text-align: left;
				margin-bottom: 1.2rem;
			}
	
			.label-name {
				font-size: 0.9rem;
				color: #666;
				margin-bottom: 1rem;
			}
	
			.input-cls {
				border: 0;
				font-size: 1rem;
				width: 16rem;
				outline: none;
				margin-bottom: 0.6rem;
				height: 1.1rem;
				line-height: 1.1rem;
			}
		}
	
		.submit-btn {
			width: 90%;
			height: 3rem;
			line-height: 3rem;
			background: #EBB149;
			border-radius: 3rem;
			text-align: center;
			font-size: 1.1rem;
			position: absolute;
			bottom: -3rem;
			left: 50%;
			transform: translate(-50%, -50%);
			cursor: pointer;
			transition: all .3s;
		}
		
		.content-cls:before {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-top: 3rem solid transparent;
			border-left: 4.5rem solid #f9f8f8;
			border-bottom: 3rem solid transparent;
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	
		.content-cls:after {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-top: 3rem solid transparent;
			border-right: 4.5rem solid #f9f8f8;
			border-bottom: 3rem solid transparent;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
	
	.address-box{
		font-size: 0;
		.img-cls{
			height: 15rem;
		}
	}
</style>
